<template>
  <UBanner>
    <article class="box">
      <h1 class="page-title">
        {{ localDocumentTitle }}
      </h1>

      <VCard>
        <VCardTitle>
          <VTextField
            v-model="search"
            append-icon="search"
            :label="$t('search.one')"
            single-line
            hide-details
          />
        </VCardTitle>

        <VDataTable
          :headers="headers"
          :items="meetings"
          :loading="loading"
          :search="search"
          :page.sync="page"
          @update:page="updatePage"
        >
          <template #[`item.created_at`]="{ item }">
            {{ formatDate(item.created_at) }}
          </template>

          <template #[`item.title`]="{ item }">
            <RouterLink :to="{ name: 'meeting', params: { meetingID: item.id } }">
              {{ item.title }}
            </RouterLink>
          </template>

          <template #[`item.provider_type`]="{ item }">
            <img
              v-if="item.provider_type.code"
              :src="`/assets/images/icon-meeting-provider-${item.provider_type.code}.svg`"
              :alt="item.provider_type.title"
              :title="item.provider_type.title"
              width="32px"
              height="32px"
            >
          </template>

          <template #[`item.starts_at`]="{ item }">
            <time>
              {{ formatDateTime(item.starts_at) }}
              <template v-if="formatDateTime(item.ends_at)">
                — {{ formatDateTime(item.ends_at) }}
              </template>
            </time>
          </template>

          <template #[`item.status`]="{ item }">
            <span>{{ getStatusTitle(item.status.code) }}</span>
          </template>
        </VDataTable>
      </VCard>
    </article>
  </UBanner>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import documentTitle from '@/mixins/documentTitle'
import { prepareMeetingStatus } from '@/utils/status'
import { UDate } from '@/utils/date'
import UBanner from '@components/UBanner.vue'
import * as getters from '@/store/getters/types'
import * as actions from '@/store/actions/types'

export default {
  name: 'TheMeetings',

  components: {
    UBanner,
  },

  mixins: [documentTitle],

  data () {
    return {
      documentTitle: '$sdo.lc_menu_meetings.two',
      loading: true,
      search: '',
      page: 1
    }
  },

  computed: {
    ...mapGetters({
      meetings: getters.MEETINGS
    }),

    headers () {
      return [
        { text: this.$t('created.one'), value: 'created_at' },
        { text: this.$t('meetings.one'), value: 'title' },
        { text: this.$t('services.one'), value: 'provider_type', sortable: false },
        { text: this.$t('periods.one'), value: 'starts_at', sortable: false },
        { text: this.$t('statuses.one'), value: 'status', sortable: false }
      ]
    }
  },

  created () {
    this.register()
  },

  methods: {
    ...mapActions({
      getMeetings: actions.GET_MEETINGS
    }),

    formatDateTime: UDate.formatDateTime,
    formatDate: UDate.formatDate,

    async register () {
      try {
        await this.getMeetings()
        this.page = parseInt(this.$route.query.page || this.page)
      } finally {
        this.loading = false
      }
    },

    getStatusTitle (code) {
      const status = prepareMeetingStatus(code)
      return this.$t(status.title)
    },

    updatePage (page) {
      const query = Object.assign({}, this.$route.query, {
        page
      })
      this.$router.replace({ query }).catch(() => {})
    }
  }
}
</script>
